<template>
    <div>
        <div class="classification">
            <h4>测评分类</h4>
            <div>
                <el-button type="primary">新增分类</el-button>

                <el-input style="width: 250px" placeholder="搜索分类名称"></el-input>
            </div>

            <div style="margin-top: 30px">
                <el-table border :data="tableData">
                           <el-table-column
                                type="index"
                                width="50">
                            </el-table-column>
                            <el-table-column prop="name" label="标签名称"></el-table-column>
                            <el-table-column prop="name" label="标签状态"></el-table-column>
                            <el-table-column prop="name" label="创建时间"></el-table-column>
                            <el-table-column prop="name" label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                                    <el-button type="text" size="small">编辑</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
            </div>
        </div>
        

    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    .classification {
        background: #fff;
        padding: 24px;
    }
    .classification >h4 {
        font-size: 16px;
        color: #1D1E1F;
        margin-bottom: 16px;
    }
    .classification >>> .el-button {
        padding-top: 0;
        padding-bottom: 0;
        height: 32px;
    }
    .classification >>> .el-input__inner {
        height: 32px;
        margin-left: 50px;
    }
</style>